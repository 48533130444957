import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => {
  const isMountedComponent = useRef(true)
  let lang;
  useEffect(() => {
    if (isMountedComponent.current) {
      lang = window.sessionStorage.getItem("appUILang") ;
    }
    return () => {
      isMountedComponent.current = false
    }
  })

  return (
    <Layout show_header={false}>
      <SEO title="404: Not found"/>
      <div className="error_page_area d-flex">
        <div className="container align-self-center">
          <div className="error_content text-center">
            <h1>404</h1>
            {lang === "es" ?
              <h2>¡Uy! Esa página no se puede encontrar</h2> :
              <h2>Oops! That page can’t be found</h2>}

            {lang === "es" ?
              <p>Lo sentimos, pero la página que estás buscando no existe</p> :
              <p>Sorry, but the page you are looking for does not existing</p>}


            {lang === "es" ?
              <Link to="/" className="btn slider_btn btn_hover hover_color"> Ir a inicio</Link> :
              <Link to="/en" className="btn slider_btn btn_hover hover_color">Go to Home</Link>}
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default NotFoundPage
